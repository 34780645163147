import React from 'react';
import { RelatedCategoriesCarousel, ConfigProvider } from '@nmg/pdp-components';

const RenderRelatedCategories = ({relatedCategories, device, foundItRelatedCategoriesToggle=false}) => {
  const inLineStyle = foundItRelatedCategoriesToggle ? { marginBottom: '10px', overflowX: 'hidden' } : { marginBottom: '10px' };
    return (relatedCategories ? (
      <>
        <ConfigProvider device={device}>
          <div className="related-categories redesign grid-100 tablet-grid-100" style={inLineStyle}>
            <RelatedCategoriesCarousel categories={relatedCategories} pdpFoundItSeoToggle={true}/>
          </div>
        </ConfigProvider>
      </>
    ) : null
  );
  }

  export default RenderRelatedCategories;